<script lang="ts">
	import { page } from "$app/stores";
	import { hosts } from "src/constants/common/hosts";
	import userStore, { UserRoles } from "src/stores/user";
	import { fade, fly } from "svelte/transition";
	import Spinner from "./common/spinner.svelte";

	let menuOpen = false;
	const userPromise = userStore.getUser();

	$: hostname = $page.url.hostname;
	$: selectedHost = hosts.find((host) => hostname.includes(host.hostname));
</script>

<header class="hidden flex-row items-center justify-between md:flex">
	{#if selectedHost}
		<a href="/">
			<img
				class="w-60 object-cover"
				alt={selectedHost.name}
				src={selectedHost.img}
			/>
		</a>
	{:else}
		<a class="hidden md:flex" href="/">
			<img alt="" src="/img/index/header/logo_txm.svg" />
		</a>
	{/if}

	{#await userPromise}
		<Spinner color="neutral-500" size="4rem" />
	{:then user}
		{#if user}
			<div class="flex flex-row items-center space-x-2">
				<img
					class="object-square-crop-top h-[38px] w-[38px] rounded-full bg-white"
					alt=""
					src={user.profile_picture?.path || "/img/user/no-avatar.svg"}
				/>
				<h4 class="hidden text-2xl md:block">Olá,</h4>
				<h4 class="hidden text-2xl text-think-500 md:block">
					{user.names[0]?.name || ""}
				</h4>
			</div>
		{/if}
	{/await}
	<div class=" flex flex-row items-center space-x-6">
		<!-- <div>
			<img src="/img/index/header/notification.svg" alt="" />
		</div> -->
		<div
			class="dropdown z-20 flex flex-col items-end leading-6 text-gray-brand-500"
		>
			<button
				class="hover:brightness-75"
				on:click={() => (menuOpen = !menuOpen)}
			>
				<img alt="" src="/img/index/header/menu.svg" />
			</button>
			{#if menuOpen}
				<!-- content here -->
				<div
					class="dropdown-content mt-10 flex-col space-y-4 rounded-2xl p-4"
					transition:fly|local={{ x: 0, y: -50 }}
				>
					<a
						href="https://sites.google.com/txm-methods.com/centraldechamadostxm/início"
						rel="noopener noreferrer"
						target="_blank"
					>
						<div class="flex items-center space-x-3">
							<div class="h-5 w-5">
								<img alt="" src="/img/index/header/icon_help.svg" />
							</div>
							<p>Ajuda</p>
						</div>
					</a>
					<a class="flex items-center space-x-3" href="/profile">
						<div class="h-5 w-5">
							<img alt="" src="/img/index/header/icon_edit.svg" />
						</div>

						<p>Editar Perfil/Projeto</p>
					</a>
					<a
						class="flex items-center space-x-3"
						href="/profile/#user-invite-email"
					>
						<div>
							<svg
								class="h-5 w-5"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M11 5a3 3 0 11-6 0 3 3 0 016 0zM2.615 16.428a1.224 1.224 0 01-.569-1.175 6.002 6.002 0 0111.908 0c.058.467-.172.92-.57 1.174A9.953 9.953 0 018 18a9.953 9.953 0 01-5.385-1.572zM16.25 5.75a.75.75 0 00-1.5 0v2h-2a.75.75 0 000 1.5h2v2a.75.75 0 001.5 0v-2h2a.75.75 0 000-1.5h-2v-2z"
								/>
							</svg>
						</div>

						<p>Enviar Convites</p>
					</a>
					<a class="flex items-center space-x-3" href="/teams">
						<div>
							<svg
								class="h-5 w-5"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									clip-rule="evenodd"
									d="M2.24 6.8a.75.75 0 001.06-.04l1.95-2.1v8.59a.75.75 0 001.5 0V4.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0L2.2 5.74a.75.75 0 00.04 1.06zm8 6.4a.75.75 0 00-.04 1.06l3.25 3.5a.75.75 0 001.1 0l3.25-3.5a.75.75 0 10-1.1-1.02l-1.95 2.1V6.75a.75.75 0 00-1.5 0v8.59l-1.95-2.1a.75.75 0 00-1.06-.04z"
									fill-rule="evenodd"
								/>
							</svg>
						</div>

						<p>Trocar de Projeto</p>
					</a>
					{#await userPromise then user}
						{#if user.role === UserRoles.ADMIN || user.role === UserRoles.MANAGER}
							<a class="flex items-center space-x-3" href="/manager">
								<div>
									<svg
										class="h-5 w-5"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M10 12.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" />
										<path
											clip-rule="evenodd"
											d="M.664 10.59a1.651 1.651 0 010-1.186A10.004 10.004 0 0110 3c4.257 0 7.893 2.66 9.336 6.41.147.381.146.804 0 1.186A10.004 10.004 0 0110 17c-4.257 0-7.893-2.66-9.336-6.41zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
											fill-rule="evenodd"
										/>
									</svg>
								</div>

								<p>Dashboard do Gestor</p>
							</a>
						{/if}
					{/await}
					<button
						class="flex items-center space-x-3"
						on:click={userStore.logout}
					>
						<div class="h-5 w-5">
							<img alt="" src="/img/index/header/icon_disconect.svg" />
						</div>
						<p class="text-red-600">Desconectar</p>
					</button>
				</div>
			{/if}
		</div>
		<a href="https://chat.txm.business/" target="_blank">
			<button class="button block w-28 text-think-500"> Chat </button>
		</a>
	</div>
</header>

<header class="flex md:hidden">
	{#await userPromise}
		<Spinner color="neutral-500" size="4rem" />
	{:then user}
		{#if user}
			<div class="flex h-[40px] w-full items-center justify-between">
				<img
					class="object-square-crop-top h-[38px] w-[38px] rounded-full bg-white"
					alt="profile-pic"
					src={user.profile_picture?.path || "/img/user/no-avatar.svg"}
				/>
				<button
					class=" text-[40px] text-[#7E8A9A]"
					on:click={() => (menuOpen = !menuOpen)}
				>
					<img alt="" src="/img/index/header/menu.svg" />
				</button>
			</div>
			{#if menuOpen}
				<div
					class="fixed left-0 top-0 z-50 flex max-h-[100vh] w-screen flex-col bg-white p-5 text-lg font-semibold text-[#203C47]"
				>
					<div
						id="user-info"
						class="mb-5 flex items-center justify-center gap-5"
					>
						<img
							class="object-square-crop-top m-0 h-[38px] w-[38px] rounded-full bg-white"
							alt=""
							src={user.profile_picture?.path || "/img/user/no-avatar.svg"}
						/>
						<h1 class="text-lg">
							Olá, <span class="text-[#3151F4]">{user.names[0].name}</span>!
						</h1>
					</div>
					<a href="https://chat.txm.business/" target="_blank">
						<button class="button block w-full text-think-500">Chat</button>
					</a>

					<div
						id="two-btn-container"
						class="mt-5 flex w-full justify-center gap-2"
					>
						<a
							class="flex h-[40px] w-1/2 items-center justify-center rounded-2xl bg-[#E3E9EC]"
							href="https://sites.google.com/txm-methods.com/centraldechamadostxm/início"
							rel="noopener noreferrer"
							target="_blank"
						>
							<div class="flex items-center space-x-3">
								<div class="h-5 w-5">
									<img alt="" src="/img/index/header/icon_help.svg" />
								</div>
								<p>Ajuda</p>
							</div>
						</a>

						<div class="flex w-1/2 justify-center rounded-2xl bg-[#E3E9EC]">
							<a class="flex items-center space-x-3" href="/profile">
								<div class="h-5 w-5">
									<img alt="" src="/img/index/header/icon_edit.svg" />
								</div>
								<p>Editar Projeto</p>
							</a>
						</div>
					</div>
					<a
						class="mt-5 flex h-[40px] items-center justify-center space-x-3 rounded-2xl bg-[#E3E9EC] font-semibold"
						href="/profile/#user-invite-email"
					>
						<div>
							<svg
								class="h-5 w-5"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M11 5a3 3 0 11-6 0 3 3 0 016 0zM2.615 16.428a1.224 1.224 0 01-.569-1.175 6.002 6.002 0 0111.908 0c.058.467-.172.92-.57 1.174A9.953 9.953 0 018 18a9.953 9.953 0 01-5.385-1.572zM16.25 5.75a.75.75 0 00-1.5 0v2h-2a.75.75 0 000 1.5h2v2a.75.75 0 001.5 0v-2h2a.75.75 0 000-1.5h-2v-2z"
								/>
							</svg>
						</div>

						<p>Enviar Convites</p>
					</a>
					<a
						class="mt-5 flex h-[40px] items-center justify-center space-x-3 rounded-2xl bg-[#E3E9EC] font-semibold"
						href="/teams"
					>
						<div>
							<svg
								class="h-5 w-5"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									clip-rule="evenodd"
									d="M2.24 6.8a.75.75 0 001.06-.04l1.95-2.1v8.59a.75.75 0 001.5 0V4.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0L2.2 5.74a.75.75 0 00.04 1.06zm8 6.4a.75.75 0 00-.04 1.06l3.25 3.5a.75.75 0 001.1 0l3.25-3.5a.75.75 0 10-1.1-1.02l-1.95 2.1V6.75a.75.75 0 00-1.5 0v8.59l-1.95-2.1a.75.75 0 00-1.06-.04z"
									fill-rule="evenodd"
								/>
							</svg>
						</div>
						<p>Trocar de Projeto</p>
					</a>

					{#await userPromise then user}
						{#if user.role === UserRoles.ADMIN || user.role === UserRoles.MANAGER}
							<a
								class="mt-5 flex h-[40px] items-center justify-center space-x-3 rounded-2xl bg-[#E3E9EC] font-semibold"
								href="/manager"
							>
								<div>
									<svg
										class="h-5 w-5"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M10 12.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" />
										<path
											clip-rule="evenodd"
											d="M.664 10.59a1.651 1.651 0 010-1.186A10.004 10.004 0 0110 3c4.257 0 7.893 2.66 9.336 6.41.147.381.146.804 0 1.186A10.004 10.004 0 0110 17c-4.257 0-7.893-2.66-9.336-6.41zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
											fill-rule="evenodd"
										/>
									</svg>
								</div>

								<p>Dashboard do Gestor</p>
							</a>
						{/if}
					{/await}

					<button
						class="mt-5 flex h-[40px] items-center justify-center space-x-3 rounded-2xl bg-[#E3E9EC] font-semibold"
						on:click={userStore.logout}
					>
						<div class="h-5 w-5">
							<img alt="" src="/img/index/header/icon_disconect.svg" />
						</div>
						<p class="text-red-600">Desconectar</p>
					</button>
				</div>
			{/if}
		{/if}
	{/await}
</header>

{#if menuOpen}
	<div
		class="fixed left-0 top-0 z-10 h-screen w-screen bg-neutral-100 opacity-30"
		on:click={() => {
			menuOpen = false;
		}}
		transition:fade|local
	/>
{/if}

<style>
	.dropdown-content {
		position: absolute;
		background-color: #fff;
		min-width: 250px;
		z-index: 1;
		box-shadow:
			-3px -4px 4px rgba(255, 255, 255, 0.25),
			2px 5px 9px rgba(0, 0, 0, 0.2);
	}
	.dropdown {
		position: relative;
	}
</style>
